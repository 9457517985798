import styled, { css } from 'styled-components';
import colors from '../Variables/colors';

export const PanelCollapse = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 20px;
`;

export const PanelCollapseButton = styled.button`
  display: flex;
  gap: 8px;
  margin-left: auto;
  background: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
    
  div {
    position: absolute;
    background: white;
    top: -40px;
    right: 70px;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    padding: 8px;
    opacity: 0;
    transition: opacity 0.1s;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export const PanelCollapseButtonText = styled.span`
  color: rgb(73, 74, 77);
  font-size: 14px;
  font-weight: 400;
`;

interface PanelCollapseButtonIconProps {
  $active: boolean;
}

export const PanelCollapseButtonIcon = styled.img<PanelCollapseButtonIconProps>`
  ${(props) =>
    props.$active &&
    css`
      rotate: 180deg;
    `}
`;

export const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0 4px 8px 0 rgba(39, 63, 103, 0.08);
  border: 1px solid rgba(158, 159, 162, 0.2);
  border-radius: 8px;

  ${PanelCollapse}.activeCall {
    ${PanelCollapseButtonText} {
      color: rgb(92, 94, 98);
    }
    ${PanelCollapseButtonIcon} {
      background-color: rgb(207 207 207);
      border-radius: 100px;
      overflow: hidden;
    }
    button:hover {
      div {
        opacity: 1;
      }
    }
  }
`;

export const TabContentWrapper = styled.div`
  padding: 0 20px 20px 20px;

  @media (max-width: 500px) {
    padding: 0 5px 20px 5px;
  }
`;

export const PanelCollapseImage = styled.img``;

export const PanelCollapseText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.textPrimary};
`;

export const PanelInfoTile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  span,
  p,
  a {
    align-self: start;
  }

  a {
    text-decoration: none;
    color: ${colors.textPrimary};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  span {
    color: ${colors.textSecondary};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  p {
    color: ${colors.textPrimary};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const getColumnTemplate = (columns: number) => {
  return `grid-template-columns: ${Array(columns).fill('1fr').join(' ')};`;
};
