import {
  PanelCollapse,
  PanelCollapseButton,
  PanelCollapseButtonIcon,
  PanelCollapseButtonText,
  PanelCollapseText,
  PanelWrapper,
  TabContentWrapper,
} from 'styles/reusable/PanelStyle';
import panelButtonIcon from 'assets/icons/arrowIcon.svg';
import React, { SVGProps, useCallback, useEffect, useState } from 'react';
import { componentMap } from './PanelImports';

interface PanelProps {
  Icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  headerText: string;
  content: string;
  isInitiallyOpen: boolean;
}

interface ComponentProps {
  collapsePanel?: () => void;
}

const Panel = ({ Icon, headerText, content, isInitiallyOpen }: PanelProps) => {
  const [showActivityPanel, setShowActivityPanel] = useState(isInitiallyOpen);
  const [tabContent, setTabContent] = useState<React.ReactNode | null>(null);

  const collapsePanel = useCallback(() => {
    setShowActivityPanel(!showActivityPanel);
  }, [showActivityPanel, setShowActivityPanel]);

  useEffect(() => {
    const Component: React.FC<ComponentProps> = componentMap[content] || null;
    setTabContent(Component ? <Component collapsePanel={collapsePanel} /> : null);
  }, []);

  return (
    <PanelWrapper>
      <PanelCollapse
        className={`panel${content}`}
        onClick={(event) => {
          if (!event.currentTarget.classList.contains('activeCall')) {
            collapsePanel();
          }
        }}
      >
        <Icon />
        <PanelCollapseText>{headerText}</PanelCollapseText>
        <PanelCollapseButton>
          <PanelCollapseButtonText>{!showActivityPanel ? 'Show' : 'Hide'}</PanelCollapseButtonText>
          <PanelCollapseButtonIcon
            src={panelButtonIcon}
            $active={showActivityPanel}
            alt={showActivityPanel ? 'Collapse' : 'Expand'}
          />
          <div>You cannot collapse the panel during an active call</div>
        </PanelCollapseButton>
      </PanelCollapse>

      {showActivityPanel && (
        <React.Suspense>
          <TabContentWrapper>{tabContent}</TabContentWrapper>
        </React.Suspense>
      )}
    </PanelWrapper>
  );
};

export default Panel;
